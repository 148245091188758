<template>
  <PaywallMessage
    class="billing-special-offer-paywall-message"
  >
    <template #text>
      <div class="billing-special-offer-paywall-message__content">
        <label>
          {{ tt('billing.specialOfferPaywallMessage.specialPlanConditions') }}
        </label>

        <Button
          :title="tt('shared.action.watch')"
          :size="ESize.small"
          :type="EButtonType.primary"
          :loading="isLoading"
          @click="handleSpecialOfferOpenAndReadingConfirm"
        />
      </div>
    </template>
  </PaywallMessage>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

import { Button } from '@/ui';
import { ESize, EButtonType } from '@/ui/types';
import tt from '@/i18n/utils/translateText';
import PaywallMessage from '@/components/PaywallMessage/index.vue';

import { useConfirmReadingSpecialOffer } from '../../application/useConfirmReadingSpecialOffer';

export default defineComponent({
  name: 'BillingSpecialOfferPaywallMessage',
  components: {
    Button,
    PaywallMessage,
  },
  setup() {
    const {
      isLoading,

      handleSpecialOfferOpenAndReadingConfirm,
    } = useConfirmReadingSpecialOffer();

    return {
      isLoading,
      ESize,
      EButtonType,

      tt,
      handleSpecialOfferOpenAndReadingConfirm,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
