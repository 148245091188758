import { computed } from 'vue';

import useStore from '@/store/useStore';
import { TUserResponse } from '@/contexts/accountingContext/domain/types';
import useExpirationTimer from '@/composables/useExpirationTimer';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useUserStore } from '@/stores';

export const useView = () => {
  const store = useStore();

  const isPiniaMigrationStoreUserAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreUser));

  const user = computed<TUserResponse>(() => {
    if (isPiniaMigrationStoreUserAvailable.value) {
      return useUserStore.user;
    }
    return store.getters['user/currentUser'];
  });

  const isOTP = computed(() => user.value?.oneTimePassword);
  const passwordExpiresAt = computed(() => user.value?.passwordExpiresAt); // Дата валидности пароля
  const lockedSince = computed(() => user.value?.lockedSince); // Дата блокировки аккаунта

  // Таймер истечения срока валидности пароля
  // TODO: А что это за таймер и зачем он нужен? Это самый простой способ проверить, не истёк ли пароль?
  const passwordExpirationTimer = useExpirationTimer({
    expirationDateRef: passwordExpiresAt,
    labelOnFinish: '',
    suffix: '',
  }).expirationValue;

  return {
    user,
    passwordExpirationTimer,
    passwordExpiresAt,
    isOTP,
    lockedSince,
  };
};
