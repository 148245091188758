import {
  computed,
  ref,
  watch,
} from 'vue';

import useStore from '@/store/useStore';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { IS_LOADING } from '@/store/modules/tenants/mutation-types';
import { TDomainError } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useUserStore } from '@/stores';

export const useAppGlobalEntities = () => {
  const store = useStore();

  const isGlobalEntitiesLoading = ref(false);

  const isPiniaMigrationStoreUserAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreUser));

  const isTenantLoading = computed(() => store?.getters['tenants/tenantLoading']);
  const userTenants = computed(() => store?.getters['tenants/availableUserTenants']);
  const isUserLoading = computed(() => {
    if (isPiniaMigrationStoreUserAvailable.value) {
      return useUserStore.isUserLoading;
    }
    return store?.getters['user/userLoading'];
  });

  const currentUserData = computed(() => {
    if (isPiniaMigrationStoreUserAvailable.value) {
      return useUserStore.user;
    }
    return store?.getters['user/currentUser'];
  });

  /** Если oneTimePassword === true, то fetchTenant - отдаст 403, т.к. не будет доступа к tenants
    * чтобы не делать ненужного запроса и не ловить ошибку - добавим проверку на oneTimePassword(OTP) */
  const isUserLoggedInByOTP = computed(() => {
    if (isPiniaMigrationStoreUserAvailable.value) {
      return useUserStore.user?.lockedSince && useUserStore.user?.oneTimePassword;
    }
    return store.getters['user/currentUser']?.lockedSince && store.getters['user/currentUser']?.oneTimePassword;
  });

  const setGlobalEntitiesLoading = (isLoading: boolean) => {
    isGlobalEntitiesLoading.value = isLoading;
  };

  const fetchGlobalEntities = () => {
    if (isPiniaMigrationStoreUserAvailable.value) {
      if (useUserStore.user && !isUserLoggedInByOTP.value) {
        return store.dispatch('tenants/fetchTenants')
          .then(() => store.dispatch('tenants/fetchCurrentTenantInfo')
            .catch((error: TDomainError) => {
              throw error;
            })
            .finally(() => store.commit(`tenants/${IS_LOADING}`, false)))
          .catch((error: TDomainError) => {
            store.dispatch(`app/${RESET_AUTH_STATE}`);
            throw error;
          });
      }

      if (!useUserStore.user) {
        return useUserStore.loadUser()
          .then(() => {
            if (!isUserLoggedInByOTP.value) {
              return store.dispatch('tenants/fetchTenants')
                .then(() => store.dispatch('tenants/fetchCurrentTenantInfo')
                  .catch((error: TDomainError) => {
                    throw error;
                  })
                  .finally(() => store.commit(`tenants/${IS_LOADING}`, false)),
                )
                .catch((error: TDomainError) => {
                  throw error;
                });
            }
            return Promise.resolve();
          })
          .catch((error: TDomainError) => {
            store.dispatch(`app/${RESET_AUTH_STATE}`);
            throw error;
          });
      }
      // TODO: выпилить весь блок else вместе с FF piniaMigrationStoreUser
    } else {
      store.commit(`user/${IS_LOADING}`, true);
      store.commit(`tenants/${IS_LOADING}`, true);
      if (currentUserData.value && !isUserLoggedInByOTP.value) {
        return store.dispatch('tenants/fetchTenants')
          .catch((error: TDomainError) => {
            store.dispatch(`app/${RESET_AUTH_STATE}`);
            throw error;
          })
          .finally(() => {
            store.commit(`user/${IS_LOADING}`, false);
            store.commit(`tenants/${IS_LOADING}`, false);
          });
      }

      if (!currentUserData.value) {
        return store.dispatch('user/fetchUser')
          .then(() => {
            if (!isUserLoggedInByOTP.value) {
              return store.dispatch('tenants/fetchTenants');
            }
          })
          .catch((error: TDomainError) => {
            store.dispatch(`app/${RESET_AUTH_STATE}`);
            throw error;
          })
          .finally(() => {
            store.commit(`user/${IS_LOADING}`, false);
            store.commit(`tenants/${IS_LOADING}`, false);
          });
      }
    }
    return Promise.resolve();
  };

  // Смотрим на изменение стейта загрузки пользователя и тенантов
  // Сетим isGlobalEntitiesLoading для того чтобы избежать рывков в анимации лоадера приложения
  watch(() => [isUserLoading.value, isTenantLoading.value], ([isUserLoading, isTenantLoading]: boolean[]) => {
    setGlobalEntitiesLoading(isUserLoading || isTenantLoading);
  });

  return {
    userTenants,
    isGlobalEntitiesLoading,

    fetchGlobalEntities,
  };
};
