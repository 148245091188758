import * as services from '@/contexts/accountingContext/services';
import { EWasAuthBy } from '@/constants';
import { decodeJWT } from '@/utils';
import {
  TSessionWithTokensSerialized,
  TAuthenticateContractorUserPayload,
  TAuthenticateUserPayload,
  TUserResponse,
} from '@/contexts/accountingContext/domain/types';
import { TReachedResponse } from '@/api/types';

import * as types from './mutation-types';
import { TUserActions } from './types';

export const actions: TUserActions = {
  signIn: ({ dispatch, state }, payload: TAuthenticateUserPayload | TAuthenticateContractorUserPayload) => services
    .authenticateUser(payload)
    .then((data: TSessionWithTokensSerialized | null) => {
      if (data?.accessToken) {
        const tokenPayload: any = decodeJWT(data?.accessToken);
        let wasAuthBy;
        // Вход в общий ТМС(логин+пароль или логин+пароль+код компании)
        if (tokenPayload?.user_id) {
          wasAuthBy = EWasAuthBy.loginPassword;
          // Вход в ЛК Контрагента
        } else if (tokenPayload?.entry_uid) {
          wasAuthBy = EWasAuthBy.entryCode;
        }

        dispatch('app/setWasAuthBy', wasAuthBy, { root: true });
        dispatch('app/setAuthTokens', data, { root: true });

        // Если вошли из-под TMS, то получаем пользователя и диспатчим proceedAuthentication
        if (wasAuthBy === EWasAuthBy.loginPassword) {
          dispatch('fetchUser').then(() => {
            dispatch('app/proceedAuthentication', state.data, { root: true });
          });
        } else if (wasAuthBy === EWasAuthBy.entryCode) {
          // Если вошли из-под ЛК контрагента, то меняем стейт на "authenticated"
          dispatch('app/setAuthenticatedMode', undefined, { root: true });
        }
      }
      return data;
    }),
  socialSignIn: ({ dispatch, state }, payload: TSessionWithTokensSerialized) => {
    if (payload.accessToken) {
      const wasAuthBy = EWasAuthBy.social;

      dispatch('app/setWasAuthBy', wasAuthBy, { root: true });
      dispatch('app/setAuthTokens', payload, { root: true });

      dispatch('fetchUser').then(() => {
        dispatch('app/proceedAuthentication', state.data, { root: true });
      });
      return Promise.resolve();
    }
    return Promise.reject();
  },
  signUp: ({ dispatch, state }, {
    firstName,
    lastName,
    phone,
    email,
    password,
    utmSources,
  }) => services
    .registerUser({
      firstName,
      lastName,
      phone,
      email,
      password,
      utmSources,
    })
    .then((data) => {
      if (data?.accessToken) {
        dispatch('app/setAuthTokens', data, { root: true });
        dispatch('fetchUser').then(() => {
          dispatch('app/proceedAuthentication', state.data, { root: true });
        });
      }
      return data;
    }),
  socialSignUp: ({ dispatch, state }, {
    firstName,
    lastName,
    phone,
    email,
    password,
  }) => services
    .registerUserBySberBusinessAccount({
      firstName,
      lastName,
      phone,
      email,
      password,
    })
    .then((data) => {
      if (data?.accessToken) {
        dispatch('app/setWasAuthBy', EWasAuthBy.social, { root: true });
        dispatch('app/setAuthTokens', data, { root: true });
        dispatch('fetchUser').then(() => {
          dispatch('app/proceedAuthentication', state.data, { root: true });
        });
      }
      return data;
    }),
  fetchUser: ({ commit }) => {
    commit(types.IS_LOADING, true);
    return services.fetchUser()
      .then((response: TReachedResponse<TUserResponse>) => {
        if (!response?.data) return;
        commit(types.SET_USER, response.data);
      })
      .finally(() => {
        commit(types.IS_LOADING, false);
      });
  },
  resetState: ({ commit }) => {
    commit(types.RESET_STATE);
  },
};
