import { computed } from 'vue';

import useViewState from '@/composables/useViewState';
import { confirmReadingSpecialOffer } from '@/contexts/billingContext/services';
import { showErrorNotification } from '@/utils';
import useStore from '@/store/useStore';
import { TSubscription } from '@/types';

import { EBillingPlanCodeStatus } from '../domain/constants';

export const useConfirmReadingSpecialOffer = () => {
  const store = useStore();

  const {
    isLoading,
    setViewStateAs,
  } = useViewState();

  const activeSubscription = computed(
    () => store.state.tenants.currentTenantInfo?.subscriptions?.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null,
  );

  const specialOffer = computed(() => activeSubscription.value?.specialOffer);

  const handleSpecialOfferOpenAndReadingConfirm = () => {
    window.open(specialOffer.value?.link || '', '_blank');

    if (!!specialOffer.value?.confirmedAt || isLoading.value) return;

    setViewStateAs.loading();

    confirmReadingSpecialOffer()
      .then(() => {
        store.dispatch('tenants/fetchCurrentTenantInfo');
      })
      .catch(showErrorNotification)
      .finally(setViewStateAs.done);
  };

  return {
    isLoading,

    handleSpecialOfferOpenAndReadingConfirm,
  };
};
