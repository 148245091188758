import { computed } from 'vue';

import { EUserSystemRole } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import store from '@/store';
import { useUserStore } from '@/stores';

const isPiniaMigrationStoreUserAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreUser));

const isCurrentUserAdmin = () => {
  if (isPiniaMigrationStoreUserAvailable.value) {
    return useUserStore.user?.systemRole === EUserSystemRole.admin;
  }
  return store.state.user?.data?.systemRole === EUserSystemRole.admin;
};

export default isCurrentUserAdmin;
