<template>
  <AuthLayout>
    <AppTitle :title="title" />

    <main class="update-password-view">
      <header class="update-password-view__logo">
        <AppLogo />
      </header>

      <Card
        :title="title"
        class="update-password-view__card"
      >
        <Form
          ref="formRef"
          :rules="formRules"
          :model="formData"
          :layout="ELayout.vertical"
          autocomplete="off"
          @finish="handleFinish"
        >
          <div class="update-password-view__view__description-container">
            <p class="update-password-view__description">
              {{ descriptionMessage }}
            </p>
          </div>

          <template v-if="isOTP">
            <FormItem
              name="password"
              :label="`${tt('shared.newPassword')}`"
            >
              <PasswordInput
                v-model:value="formData.password"
                @change="handleInputChange('password')"
                @blur="handleInputChange('password')"
              />

              <template #help>
                <CustomFormItemHelp
                  :rules="formRules.password"
                  :value="formData.password"
                />
              </template>
            </FormItem>
          </template>

          <template v-else>
            <FormItem
              name="oldPassword"
              :label="`${tt('shared.oldPassword')}`"
            >
              <PasswordInput
                v-model:value="formData.oldPassword"
                @change="handleInputChange('oldPassword')"
                @blur="handleInputChange('oldPassword')"
              />
            </FormItem>

            <FormItem
              name="newPassword"
              :label="`${tt('shared.newPassword')}`"
            >
              <PasswordInput
                v-model:value="formData.newPassword"
                @change="handleInputChange('newPassword')"
                @blur="handleInputChange('newPassword')"
              />

              <template #help>
                <CustomFormItemHelp
                  :rules="formRules.newPassword"
                  :value="formData.newPassword"
                />
              </template>
            </FormItem>
          </template>

          <div class="update-password-view__buttons">
            <Button
              type="link"
              @click="handleLogoutClick"
            >
              {{ tt('sider.info.logOff') }}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              class="update-password-view__button"
            >
              {{ tt("shared.updateAndContinue") }}
            </Button>
          </div>
        </Form>
      </Card>
    </main>
  </AuthLayout>
</template>

<script lang="ts">
import {
  Button,
  Card,
  Form,
  FormItem,
} from 'ant-design-vue';
import {
  computed,
  defineComponent,
  reactive,
  ref,
} from 'vue';

import { PasswordInput } from '@/ui';
import useViewState from '@/composables/useViewState';
import { useFormHandlers } from '@/composables/useFormHandlers';
import { EViewState, ELayout } from '@/constants';
import AuthLayout from '@/components/layouts/AuthLayout/index.vue';
import tt from '@/i18n/utils/translateText';
import AppLogo from '@/components/AppLogo/index.vue';
import useStore from '@/store/useStore';
import { editUserOTP, editUserPassword } from '@/contexts/accountingContext/services';
import CustomFormItemHelp from '@/components/CustomFormItemHelp/index.vue';
import { notification } from '@/utils';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { useUserStore } from '@/stores';
import { TDomainError } from '@/types';

import { useInfrastructureConstants } from '../composables/useInfrastructureConstants';

export default defineComponent({
  name: 'UpdatePasswordView',
  components: {
    AppLogo,
    AuthLayout,
    Card,
    Form,
    FormItem,
    PasswordInput,
    Button,
    CustomFormItemHelp,
  },
  props: {
    isOTP: { // One Time Password
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isPiniaMigrationStoreUserAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreUser));
    const formRef = ref<typeof Form | null>(null);
    const formData = reactive({
      oldPassword: '',
      newPassword: '',
      password: '',
    });

    const { updatePasswordRules, resetPasswordRules } = useInfrastructureConstants();
    const {
      onControlChange,
      onError,
      formRules,
      commonError,
    } = useFormHandlers(
      formRef,
      { rules: props.isOTP ? resetPasswordRules.value : updatePasswordRules.value },
    );

    const {
      isLoading,
      setViewState,
    } = useViewState();
    const store = useStore();

    // @ts-ignore
    const commonErrorText = computed(() => commonError?.value?.message || '');
    const descriptionMessage = computed(() => (
      props.isOTP
        ? tt('infrastructure.description.specifyOwnPassword')
        : tt('infrastructure.description.passwordExpired')
    ));

    const title = computed(() => (
      props.isOTP
        ? tt('infrastructure.title.createPassword')
        : tt('infrastructure.title.updatePassword')
    ));

    const handleLogoutClick = () => {
      store.dispatch('app/logout');
    };

    const handleInputChange = (name: 'oldPassword' | 'newPassword' | 'password') => {
      onControlChange({ name });
    };

    const handleFinish = () => {
      setViewState(EViewState.loading);

      (props.isOTP
        ? editUserOTP({ newPassword: formData.password })
        : editUserPassword({
          newPassword: formData.newPassword,
          oldPassword: formData.oldPassword,
        }))
        .then(() => {
          setViewState(EViewState.done);

          if (isPiniaMigrationStoreUserAvailable.value) {
            useUserStore.loadUser()
              .then(() => {
                store.dispatch('app/proceedAuthentication', useUserStore.user);
              })
              .catch((error: TDomainError) => {
                throw error;
              });
          } else {
            store.dispatch('user/fetchUser')
              .then(() => store.dispatch('app/proceedAuthentication', store.getters['user/currentUser']));
          }
        }).catch((error: TDomainError) => {
          setViewState(EViewState.withError);

          onError(error);

          notification.error({ message: tt('account.notification.error.editPassword') });
        });
    };

    return {
      formData,
      formRef,
      formRules,
      commonErrorText,
      ELayout,
      isLoading,
      descriptionMessage,
      title,

      tt,
      handleFinish,
      handleInputChange,
      handleLogoutClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
