import { ERightPanelSection, TRightPanelState } from './types';

export const initialState = (): TRightPanelState => ({
  [ERightPanelSection.executionRequestList]: {
    isOpenRoutes: false,
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.executorsLookupsFeed]: {
    isOpenRoutes: false,
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.orderList]: {
    isOpenRoutes: false,
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.operationalDocumentsList]: {
    isOpenRoutes: false,
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.referencesList]: {
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.selfBillingView]: {
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.partnershipResourcesDrivers]: {
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
  [ERightPanelSection.partnershipResourcesVehicles]: {
    isOpenRightPanel: false,
    rightPanelWidth: 0,
  },
});
