import { computed } from 'vue';

import tt from '@/i18n/utils/translateText';
import useStore from '@/store/useStore';
import { generatePasswordRules } from '@/domains/generatePasswordRules';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useUserStore } from '@/stores';

const requiredRule = {
  required: true,
  message: tt('shared.rule.passwordRequired'),
  trigger: 'blur',
};

export const useInfrastructureConstants = () => {
  const store = useStore();
  const isPiniaMigrationStoreUserAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreUser));

  const complexityCheck = computed(() => {
    if (isPiniaMigrationStoreUserAvailable.value) {
      return store.getters['tenants/complexityCheck'] || useUserStore.user?.ownerTenantSettings?.passwordComplexitySettings?.complexityCheck;
    }
    return store.getters['tenants/complexityCheck'] || store.getters['user/complexityCheck'];
  });

  const updatePasswordRules = computed(() => ({
    oldPassword: [
      requiredRule,
    ],
    newPassword: [
      requiredRule,
      ...generatePasswordRules(complexityCheck.value),
    ],
  }));

  const resetPasswordRules = computed(() => ({
    password: [
      requiredRule,
      ...generatePasswordRules(complexityCheck.value),
    ],
  }));

  return {
    updatePasswordRules,
    resetPasswordRules,
  };
};
